<template>
	<div class="ke_box">
		<div>
			<div class="kelia">
				<img src="../../assets/img/images/kb.png" class="kepic" alt="" />
				<div class="ketxt">0818-3333218   17111222200</div>
			</div>
			<div class="keli">
				<img src="../../assets/img/images/ka.png" class="kepic" alt="" />
				<img src="../../assets/img/images/ma.png" class="mapic" alt="" />
			</div>

			<div class="kelia">
				<img src="../../assets/img/images/kc.png" class="kepic" alt="" />
				<div class="ketxtact">657713514@qq.com</div>
			</div>
			<div class="keli" @click="scrollToTop">
				<img src="../../assets/img/images/kd.png"  class="kepic" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'kefuright',
		data() {
			return {};
		},
		created() {

		},
		mounted() {},
		methods: {
			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth' // 可选，平滑滚动
				});
			}
		}
	};
</script>

<style lang='less' scoped>
	.ke_box {
		position: fixed;
		right: 0;
		top: 30vh;
		z-index: 66;
	}

	.keli {
		background-color: #ffffff;
		border: 1px solid #dddddd;
		margin-bottom: 10px;
		border-radius: 10px 0 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		transition: all 1s;
		position: relative;
		cursor: pointer;
	}

	.kelia {
		background-color: #ffffff;
		border: 1px solid #dddddd;
		margin-bottom: 10px;
		border-radius: 10px 0 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		transition: all 1s;
		position: relative;
		cursor: pointer;
	}

	.kepic {
		width: 25px;
		flex-shrink: 0;
		height: 25px;
	}

	.mapic {
		width: 90px;
		height: 90px;
		position: absolute;
		left: 100px;
		top: 0;
		opacity: 0;
		z-index: -9999;
		transition: all 0.5s;
	}

	.ketxt {
		font-size: 14px;
		color: #333333;
		width: 150px;
		height: 50px;
		line-height: 25px;
		text-align: center;
		border: 1px solid #dddddd;
		border-right: none;
		border-radius: 10px 0 0 10px;
		position: absolute;
		top: -1px;
		left: 70px;
		opacity: 0;
		z-index: -9999;
		transition: all 0.5s;
		background-color: #ffffff;
	}
	.ketxtact {
		font-size: 14px;
		color: #333333;
		width: 150px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		border: 1px solid #dddddd;
		border-right: none;
		border-radius: 10px 0 0 10px;
		position: absolute;
		top: -1px;
		left: 70px;
		opacity: 0;
		z-index: -9999;
		transition: all 0.5s;
		background-color: #ffffff;
	}

	.kelia:hover {
		width: auto;
		padding: 0 10px;
	}

	.kelia:hover .ketxt {
		left: -140px;
		opacity: 66;
		z-index: 1;
	}
	.kelia:hover .ketxtact {
		left: -140px;
		opacity: 66;
		z-index: 1;
	}
	.keli:hover .mapic {
		z-index: 66;
		opacity: 1;
		left: -92px;
	}
</style>